<template>
  <div>

    <div class="row">
      <div class="col-12 col-xl-3 mb-3">
            <div class="card">
              <div class="card-body">
                <p class="card-title">
                  Create {{ $route.meta.title }}
                </p>

                <form
                  @submit.prevent="postForm"
                >
                  <div class="form-group">
                    <label for="name">{{ $route.meta.title }} Name</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
                </form>
              </div><!--Body-->
            </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                >
              </div>

              <div class="form-group">
                <label for="export">Auto-Export Frequency</label>
                <select 
                  id="export"
                  v-model="queries.export"
                  class="form-control" 
                  :disabled="processing"
                >
                </select>
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select 
                  id="created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option 
                      :key="'user_'+index" 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


      </div><!--Col-->
      <div class="col-12 col-xl-9">

        <div class="card">
          <div class="card-body">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
              </p>

              <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>


          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

    data () {
        return {
            processing: false,
            records: [],
            form: {},
            queries: {},
            total: 0,
            export_frequencies: [],
            users: [],
            columns: [
            { key: 'name', label: 'Name'},
            { key: 'export', label: 'Auto-Export'},
            { key: 'status', label: 'Status'},
            { key: 'created_by', label: 'Creator'},
            { key: 'last_active', label: 'Last Exported'},
            { key: 'created_at', label: 'Created'}
            ],
        }
    },

    mounted() {
      this.refreshRecords();
    },

    created () {

        this.resetForm();
        this.resetQueries();
        this.getRecords();
        this.getUsers();
    },

    methods: {


        resetQueries() {
            this.queries = {
                search: null,
                created_by: null,
                limit: 10,
                trashed: false,
                export: null,
                sort: 'last_active',
                sortDirection: 'desc'
            }
        },

        resetForm() {
            this.form = {
                name: null
            }
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },
    
        getUsers() {
          this.$http.get('/users?limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.status) {

                  this.processing = false;
                  
                  if(response.data) {
                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });

                    this.queries.skip = this.records.length; 
                  }
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's';
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();
          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        },

        postForm() {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                this.form = {}
                if(response && response.status === 201) {
                    this.records.unshift(response.data);
                    this.total++;
                    this.setTitle();
                    this.resetForm();
                    this.$eventBus.$emit('alerts', {message: this.$route.meta.title+' added successfully.', type: 'success'});
                    this.processing = false;
                }
            });
        }
    }
}
</script>
