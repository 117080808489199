import Vue from 'vue';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';


if(process.env.NODE_ENV != 'development') {

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG,
  plugins: [new BugsnagPluginVue()],
  logger: null
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);
}

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
Object.defineProperty(Vue.prototype, '$leaflet', { value: leaflet });

import axios from 'axios';
Object.defineProperty(Vue.prototype, '$axios', { value: axios });

import * as bootstrap from 'bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';

import './styles.scss';

import App from './views/App.vue';

const components = require.context('./components', true, /\.vue$/i);
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default));

import router from './router.js';
import http from './http.js';
import filters from './filters.js';
import auth from './mixins/auth';
import queries from './mixins/queries';

Vue.mixin(queries);
Vue.mixin(auth);

var store = {
  user: {},
  agency: {},
  title: 'GovPayPlan',
  logo: '/images/gpp-logo.png',
  logo_light: '/images/gpp-logo-white.png',
  coordinates: [37.090240, -95.712891],
  map_layer: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  mapbox_url: 'https://api.mapbox.com/styles/v1/ecourtdate/',
  mapbox_token: 'pk.eyJ1IjoiZWNvdXJ0ZGF0ZSIsImEiOiJjbDJoMTM5MmUwMXVqM2ltaHkwZW4zcXQ1In0.LMUuvwP7jONVzWPzk-G1MA',  
  payment_statuses: ['draft', 'outstanding', 'paid', 'failed', 'refunded', 'voided'],
  assets_url: 'https://ecdassets.s3.amazonaws.com/',
  card_brands: ['visa', 'mastercard', 'discover', 'amex'],
  pay_methods: ['cash', 'card', 'ach', 'check', 'invoice']
}

Vue.config.productionTip = false;

new Vue({
  router,
  http,
  filters,
  data: store,
  render: h => h(App)
}).$mount('#app');

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('access_token');
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
  if (!requiresAuth) next()
  if(!token && requiresAuth) {
    next({ name: 'login' });
  } else {
    next()
  }

});