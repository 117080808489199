<template>
<div>

  <archived-trashed-status
      :form="form"
      :title="$route.meta.title"
      :hidden="processing"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1>
          {{ $route.meta.title }}
        </h1>

        <p class="d-print-none"><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
      </div><!--Col-->

      <div class="col-sm-12 col-md-2 text-end">

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-end">

        <div class="btn-group d-print-none">

          <button type="button" class="btn btn-danger" @click="trashRecord()" :disabled="processing" :hidden="form.deleted_at">
            <i class="fa fa-trash"></i> <span class="sr-only">Trash</span>
          </button>

          <button type="button" class="btn btn-secondary" @click="duplicateRecord()" :disabled="processing">
            <i class="fa fa-copy"></i> <span class="sr-only">Duplicate</span>
          </button>

          <button type="button" class="btn btn-secondary" @click="restoreRecord()" :disabled="processing" :hidden="!form.deleted_at">
            <i class="fa fa-undo"></i> <span class="sr-only">Restore</span>
          </button>

          <button
            type="button"
            class="btn btn-success d-print-none"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger d-print-none"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->
</div>
</template>
<script>
export default {

props: ['form'],

data() {
  return {
    processing: false
  }
},

created() {
},

methods: {

  postForm() {
    this.processing = true;
    this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.status === 200) {
          this.processing = false;
          this.$eventBus.$emit('alerts', {type: 'success', message: 'Saved '+this.$route.meta.title+'.'});
        }
      })
      .catch(error => {
        console.log(error)
      })
  },

  trashRecord() {
    this.processing = true;
    this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if(response && response.status === 204) {
          this.$router.go();
          this.processing = false;
        }
      })
      .catch(error => {
        console.log(error)
      })
  },

  duplicateRecord() {
  },

  restoreRecord() {
  },

}

}
</script>