<template>
  <div>

    <tool-bar
      :form="form"
    />

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="amount">Amount</label>
            <input
              id="amount"
              v-model="form.amount"
              type="text"
              class="form-control"
              :disabled="processing"
              required
            >
          </div>
        </div><!--Col-->
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="reference">Payment Reference</label>
            <input
              id="reference"
              v-model="form.reference"
              type="text"
              class="form-control"
            >
          </div>
        </div><!--Col-->
      </div><!--Row-->

      <div class="form-group">
        <label for="contact">Contact</label>
        <input id="contact" type="text" class="form-control" v-model="form.contact">
        <small class="form-text text-muted">Phone number or email address of the payer.</small>
      </div>

          <div class="form-group">
            <label for="payment_location">Location

        <template v-if="form.location">
          <a
          :href="'/locations/'+form.location"
          target="_blank"
          data-tooltip="Edit Location">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
          </a>
        </template>

            </label>
            <select
              id="payment_location"
              v-model="form.location"
              class="form-control"
              :disabled="processing"
            >
              <option value=""></option>
              <template v-for="location in locations">
                <option :value="location.uuid">
                  {{ location.name }}
                </option>
              </template>
            </select>
          </div>


      <div class="form-group">
        <label for="description">Description</label>
        <textarea
          id="description"
          v-model="form.description"
          class="form-control"
          rows="3"
        />
      </div>

      <div class="form-group">
        <label for="notes">Notes</label>
        <input
          id="notes"
          v-model="form.notes"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="portal">Portal

        <template v-if="form.portal">
          <a
          :href="'/portals/'+form.portal"
          target="_blank"
          data-tooltip="Edit Portal">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
          </a>
        </template>
        
        </label>
        <select
          id="portal"
          v-model="form.portal"
          class="form-control"
          :disabled="processing"
        >
          <option value="">
            Choose Portal
          </option>
          <template v-for="portal in portals">
            <option :value="portal.uuid">
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="row">
        <div class="form-group col">
          <label for="issued_at">Issue Date</label>
          <input
            id="issued_at"
            v-model="form.issued_at"
            type="date"
            class="form-control"
            :disabled="processing"
          >
        </div>
        <div class="form-group col">
          <label for="due_at">Due Date</label>
          <input
            id="due_at"
            v-model="form.due_at"
            type="date"
            class="form-control"
            :disabled="processing"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="status">Status</label>
            <select
              id="status"
              v-model="form.status"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-if="options && options['payment_statuses']">
              <option
                v-for="option in options['payment_statuses']"
                :key="option.label"
                :value="option.label"
              >
                {{ option.label }}
              </option>
              </template>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="payment_type">Type</label>
            <input
              id="payment_type"
              v-model="form.type"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="case_number">Case Number</label>
        <input id="case_number" type="text" class="form-control" v-model="form.case_number">
      </div>

      <div class="form-group">
        <label for="citation">Citation</label>
        <input id="citation" type="text" class="form-control" v-model="form.citation">
      </div>

      <div class="form-group">
        <label for="payment_description">Description</label>
        <textarea
          id="payment_description"
          v-model="form.description"
          class="form-control"
          rows="3"
        />
      </div>

      <div class="form-group">
        <label for="payment_notes">Internal Notes</label>
        <textarea
          id="payment_notes"
          v-model="form.notes"
          class="form-control"
          rows="3"
        />
      </div>

      <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
      
    </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form"></audit-logs>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6 col-lg-4">

        <template v-if="qr_code">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <a :href="portalLink" target="_blank">
                  <img
                  :src="'data:image/svg+xml;base64,'+qr_code"
                  class="img-fluid" alt="QR Code" width="120" height="120" />
                  </a>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6">

                  <p class="lead">Go to Portal</p>

                  <p>
                    <a :href="portalLink" target="_blank">
                      {{ portalLink }}
                    </a>
                  </p>

                </div><!--Col-->

              </div><!--Row-->

            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="pay_plan && pay_plan.uuid">
          <div class="card">
            <div class="card-body">
              <p class="card-title">Pay Plan</p>
              <a :href="'/pay_plans/'+pay_plan.uuid">View Pay Plan</a>
            </div>
          </div>
        </template>


        <div class="card">
          <div class="card-body">
            <p class="card-title">Create Transaction</p>

            <form @submit.prevent="postTransaction()">
              <div class="form-group">
                <label for="amount">Amount</label>
                <input
                  id="amount"
                  v-model="transactionForm.amount"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="method">Method</label>
                <select
                  id="method"
                  v-model="transactionForm.method"
                  class="form-control"
                  required
                >
                <template v-for="pay_method in $root.pay_methods">
                  <option :value="pay_method">
                    {{ pay_method }}
                  </option>
                </template>
              </select>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Create</button>

            </form>

            <template v-if="transactions && transactions.length > 0">

              <p class="card-title mt-3">{{ transactions.length }} Transactions</p>

              <p>Total: {{ totalTransactionsSum }}</p>

              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="transaction in transactions">
                    <tr>
                      <td><a :href="'/transactions/'+transaction.uuid">{{ transaction.amount }}</a></td>
                      <td>{{ transaction.method }}</td>
                      <td>{{ transaction.created_at }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-6 col-lg-4">

        <div class="card">
          <div class="card-body">
            <p class="card-title">Send a Message</p>

            <form @submit.prevent="postMessage()">
              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  v-model="messageForm.message"
                  class="form-control"
                  rows="3"
                  required
                />
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="messages && messages.length > 0">

          <p class="card-title mt-3 mb-0">{{ messages.length }} Messages</p>

          <template v-for="message in messages">
            <p class="m-0 text-muted small">To: {{ message.to }}</p>
            <template v-if="message.subject">
              <p class="m-0 text-muted small">Subject: {{ message.subject }}</p>
            </template>
            <p class="outbound m-0" v-html="message.content"></p>
            <p class="text-muted small m-0">{{ message.scheduled_at | dateTime }}</p>
            <span class="badge text-uppercase m-0" :class="setMessageStatus(message)">{{ message.last_status }}</span>
          </template>
            
          </template>


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {
    
    data () {

        return {
          processing: false,
          form: {},
          locations: [],
          portals: [],
          portal: {},
          options: [],
          transactions: [],
          transactionForm: {},
          qr_code: null,
          messageForm: {
            message: null
          },
          messages: [],
          pay_plan: null
        }
    },

    computed: {

      portalLink: function() {

        if(!this.portal || !this.portal.link) {
          return;
        }

        return 'https://'+this.portal.link+'.'+this.portal.domain+'/p/'+this.form.url;
      },

      totalTransactionsSum: function() {
        if(!this.transactions) {
          return 0;
        }

        let amount = 0;

        for (let i = 0; i < this.transactions.length; i++) {

          //parse with $
          var str = this.transactions[i].amount;

          //remove $ and commas
          str = str.replace(/[$,]/g, '');

          amount += parseFloat(str);
        }

        return '$' + amount.toFixed(2);
        
      }

    },

    mounted() {

        var self = this;

        this.$eventBus.$on('paymentUpdated', function (data) {
            self.form = {...self.form, ...data};
        });


    },

    created () {

        this.resetForm();

        this.getRecord();

        this.getOptions();
        this.getLocations();
        this.getPortals();
    },

methods: {

  getOptions() {
    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
          if (response && response.data) {
            this.options = response.data;
          }
        })
  },

  getLocations() {
    this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
        .then(response => {
          if (response && response.data) {
            this.locations = response.data;
          }
        })
  },

  getPortals() {
    this.$http.get('/portals?limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
        .then(response => {
          if (response && response.data) {
            this.portals = response.data;
          }
        })
  },

  getPortal() {

    if (!this.form.portal) {
      this.portal = {};
      this.qr_code = null;
      return;
    }

    if(this.portal && this.portal.uuid && this.portal.uuid == this.form.portal) {
      return;
    }

    this.$http.get('/portals/' + this.form.portal + '?fields=uuid,name,domain,link')
        .then(response => {
          if (response && response.data) {
            this.portal = response.data;

            this.getQRCode();
          }
        })
  },

  getQRCode() {
    this.$http.post('/qrs?content=' + this.portalLink)
        .then(response => {
          if (response && response.data) {
            this.qr_code = response.data;
          }
        })
  },

  resetForm() {
    this.form = {}

    this.qr_code = null;
  },

  

  postForm() {
    this.processing = true;
    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid, this.form)
        .then(response => {
          if (response && response.data) {

            if (response.data.updated_at) {
              this.$eventBus.$emit('alerts', {type: 'success', message: this.$route.meta.title + ' saved'});
              this.form = {...this.form, ...response.data};
            }

            this.processing = false;

          }
        })
  },

  getRecord() {
    this.processing = true;
    this.$http.get('/' + this.$route.meta.base_url + '/' + this.$route.params.id)
        .then(response => {
          if (response && response.data) {

            if (response.data.uuid) {

              this.form = response.data;

              this.getPortal();

              this.resetTransactionForm();

              this.getTransactions();

              this.getMessages();

              this.getPayPlan();

            }

            this.processing = false;
          }
        })
  },

  resetTransactionForm() {
    this.transactionForm = {
      payment: this.form.uuid,
      reference: null,
      amount: 0,
      paid_at: new Date().toISOString().substr(0, 10),
      method: 'cash'
    }
  },

  postTransaction() {
    this.processing = true;

    this.form.status = 'paid';

    this.$http.post('/transactions', this.transactionForm)
        .then(response => {
          if (response && response.data) {

            if(!this.transactions) {
              this.transactions = [];
            }

            if(response.data.uuid) {
              this.transactions.unshift(response.data);
            }

            this.resetTransactionForm();

            this.hasPaidOutstanding();

            this.processing = false;
          }
        })
  },

  hasPaidOutstanding() {
    if (!this.transactions) {
      return;
    }

    if(!this.form.amount || !this.form.status == 'outstanding') {
      return;
    }

    var total = this.totalTransactionsSum;

    total = total.replace('$', '');

    var amount = this.form.amount;

    amount = amount.replace('$', '');

    var outstanding = parseFloat(amount) - parseFloat(total);

    if(outstanding <= 0) {
      this.form.status = 'paid';
      this.postForm();
    }

  },

  getTransactions() {
    this.$http.get('/transactions?limit=1000&sort=created_at&sortDirection=desc&payment=' + this.form.uuid)
        .then(response => {
          if (response && response.data) {
            this.transactions = response.data;
          }
        })
  },

  restoreRecord() {
    this.processing = true;
    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
        .then(response => {
          if (response && response.data && response.data.message) {
            this.getRecord();
          }
        })
  },


  trashRecord() {
    this.processing = true;
    this.$http.delete('/' + this.$route.meta.base_url + '/' + this.form.uuid)
        .then(response => {
          if (response && response.data) {
          }
        })
  },

  duplicateRecord() {
    var form = this.form;

    delete form.uuid;
    delete form.updated_at;

    this.$http.post('/'+this.$route.meta.base_url, form)
        .then(response => {
          if (response && response.data) {
            this.$router.push({name: this.$route.name, params: {id: response.data.uuid}});
          }
        })
  },

    getMessages() {
      this.$http.get('/payments/'+this.form.uuid+'/messages?limit=1000&sort=created_at&sortDirection=desc')
          .then(response => {
            if (response && response.data) {
              this.messages = response.data;
            }
          })
    },


    postMessage() {
        this.processing = true;

        this.$http.post(this.$route.meta.base_url+'/'+this.$route.params.id+'/messages', {
          message: this.messageForm.message
        })
        .then(response => {
          if(response && response.data) {
            this.processing = false;
            this.messageForm.message = null;

            this.getMessages();
          }
        })
      },

      setMessageStatus(message)
      {
      if(!message.last_status) {
        return 'bg-secondary';
      }

      if(message.last_status == 'sent') {
        return 'bg-primary';
      }

      if(message.last_status == 'failed') {
        return 'bg-danger';
      }

      if(message.last_status == 'delivered') {
        return 'bg-success';
      }

      return 'bg-secondary';
    },

    getPayPlan() {
      this.$http.get('/client_plans?limit=1&payment='+this.form.uuid)
        .then(response => {
          if(response && response.data && response.data.length > 0) {
            this.pay_plan = response.data[0];
          }
        })
    }

}
}
</script>
