import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from './views/Login.vue';
import DashboardIndex from './views/DashboardIndex.vue';
import UsersIndex from './views/UsersIndex.vue';
import UsersEdit from './views/UsersEdit.vue';
import PaymentsIndex from './views/PaymentsIndex.vue';
import PaymentsEdit from './views/PaymentsEdit.vue';
import TransactionsIndex from './views/TransactionsIndex.vue';
import TransactionsEdit from './views/TransactionsEdit.vue';
import TerminalsIndex from './views/TerminalsIndex.vue';
import TerminalsEdit from './views/TerminalsEdit.vue';
import GiftCardsIndex from './views/GiftCardsIndex.vue';
import GiftCardsEdit from './views/GiftCardsEdit.vue';
import PayLinksIndex from './views/PayLinksIndex.vue';
import PayLinksEdit from './views/PayLinksEdit.vue';
import PayPlansIndex from './views/PayPlansIndex.vue';
import PayPlansEdit from './views/PayPlansEdit.vue';
import PayMethodsIndex from './views/PayMethodsIndex.vue';
import PayMethodsEdit from './views/PayMethodsEdit.vue';
import RecurringPaymentsIndex from './views/RecurringPaymentsIndex.vue';
import RecurringPaymentsEdit from './views/RecurringPaymentsEdit.vue';
import SettingsIndex from './views/SettingsIndex.vue';
import ReportsIndex from './views/ReportsIndex.vue';
import ReportsEdit from './views/ReportsEdit.vue';
import AgenciesIndex from './views/AgenciesIndex.vue';
import AgenciesEdit from './views/AgenciesEdit.vue';
import LocationsIndex from './views/LocationsIndex.vue';
import LocationsEdit from './views/LocationsEdit.vue';
import NotFound from './views/NotFound.vue';
import Logout from './views/Logout.vue';
import PortalsIndex from './views/PortalsIndex.vue';
import PortalsEdit from './views/PortalsEdit.vue';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/oauth/:id',
    name: 'oauth',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: DashboardIndex,
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },

  {
    path: '/payments',
    name: 'payments.index',
    component: PaymentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Payment',
      base_url: 'payments'
    }
  },
  {
    path: '/payments/:id',
    name: 'payments.edit',
    component: PaymentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Payment',
      base_url: 'payments'
    }
  },
  {
    path: '/transactions',
    name: 'transactions.index',
    component: TransactionsIndex,
    meta: {
      requiresAuth: true,
      title: 'Transaction',
      base_url: 'transactions'
    }
  },
  {
    path: '/transactions/:id',
    name: 'transactions.edit',
    component: TransactionsEdit,
    meta: {
      requiresAuth: true,
      title: 'Transaction',
      base_url: 'transactions'
    }
  },
  {
    path: '/terminals',
    name: 'terminals.index',
    component: TerminalsIndex,
    meta: {
      requiresAuth: true,
      title: 'Terminal',
      base_url: 'terminals'
    }
  },
  {
    path: '/terminals/:id',
    name: 'terminals.edit',
    component: TerminalsEdit,
    meta: {
      requiresAuth: true,
      title: 'Terminal',
      base_url: 'terminals'
    }
  },
  {
    path: '/gift_cards',
    name: 'gift_cards.index',
    component: GiftCardsIndex,
    meta: {
      requiresAuth: true,
      title: 'Gift Card',
      base_url: 'gift_cards'
    }
  },
  {
    path: '/gift_cards/:id',
    name: 'gift_cards.edit',
    component: GiftCardsEdit,
    meta: {
        requiresAuth: true,
        title: 'Gift Card',
        base_url: 'gift_cards'
    }
  },
  {
    path: '/pay_links',
    name: 'pay_links.index',
    component: PayLinksIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Link',
      base_url: 'pay_links'
    }
  },
  {
    path: '/pay_links/:id',
    name: 'pay_links.edit',
    component: PayLinksEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Link',
      base_url: 'pay_links'
    }
  },
  {
    path: '/pay_plans',
    name: 'pay_plans.index',
    component: PayPlansIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Plan',
      base_url: 'pay_plans'
    }
  },
  {
    path: '/pay_plans/:id',
    name: 'pay_plans.edit',
    component: PayPlansEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Plan',
      base_url: 'pay_plans'
    }
  },
  {
    path: '/recurring_payments',
    name: 'recurring_payments.index',
    component: RecurringPaymentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Recurring Payment',
      base_url: 'recurring_payments'
    }
  },
  {
    path: '/recurring_payments/:id',
    name: 'recurring_payments.edit',
    component: RecurringPaymentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Recurring Payment',
      base_url: 'recurring_payments'
    }
  },
  {
    path: '/pay_methods',
    name: 'pay_methods.index',
    component: PayMethodsIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Method',
      base_url: 'pay_methods'
    }
  },
  {
    path: '/pay_methods/:id',
    name: 'pay_methods.edit',
    component: PayMethodsEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Method',
      base_url: 'pay_methods'
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: UsersIndex,
    meta: {
      requiresAuth: true,
      title: 'User',
      base_url: 'users'
    }
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: UsersEdit,
    meta: {
      requiresAuth: true,
      title: 'User',
      base_url: 'users'
    }
  },

  {
    path: '/settings',
    name: 'settings.index',
    component: SettingsIndex,
    meta: {
      requiresAuth: true,
      title: 'Settings',
      base_url: 'settings'
    }
  },
  {
    path: '/reports',
    name: 'reports.index',
    component: ReportsIndex,
    meta: {
      requiresAuth: true,
      title: 'Report',
      base_url: 'reports'
    }
  },
  {
    path: '/reports/:id',
    name: 'reports.edit',
    component: ReportsEdit,
    meta: {
      requiresAuth: true,
      title: 'Report',
      base_url: 'reports'
    }
  },
  {
    path: '/agencies',
    name: 'agencies.index',
    component: AgenciesIndex,
    meta: {
      requiresAuth: true,
      title: 'Agency',
      base_url: 'agencies'
    }
  },
  {
    path: '/agencies/:id',
    name: 'agencies.edit',
    component: AgenciesEdit,
    meta: {
      requiresAuth: true,
      title: 'Agency',
      base_url: 'agencies'
    }
  },
  {
    path: '/locations',
    name: 'locations.index',
    component: LocationsIndex,
    meta: {
      requiresAuth: true,
      title: 'Location',
      base_url: 'locations'
    }
  },
  {
    path: '/locations/:id',
    name: 'locations.edit',
    component: LocationsEdit,
    meta: {
      requiresAuth: true,
      title: 'Location',
      base_url: 'locations'
    }
  },

  {
    path: '/portals',
    name: 'portals.index',
    component: PortalsIndex,
    meta: {
      requiresAuth: true,
      title: 'Portal',
      base_url: 'portals'
    }
  },
  {
    path: '/portals/:id',
    name: 'portals.edit',
    component: PortalsEdit,
    meta: {
      requiresAuth: true,
      title: 'Portal',
      base_url: 'portals'
    }
  },

  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      requiresAuth: false,
      title: 'Page Not Found'
    }
  },
  {
    path: '*',
    redirect: '/login',
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: false,
      title: 'Logout from eCourtDate'
    }
  }
]

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
