<template>
  <div>

    <tool-bar
      :form="form"
    />

    <div class="row">
      <div class="col-sm-12 col-md-6 d-print-none">
        <div class="card">
          <div class="card-body">

            <template v-if="setPortalLink()">
                  <a :href="setPortalLink()" target="_blank" class="btn btn-primary btn-sm">
                    <i class="fa fa-external-link-alt"></i> Open Portal Link
                  </a>                
            </template>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="text" class="form-control" v-model="form.amount" step="0.01" min="0" max="1000" required readonly>
              </div>

              <div class="form-group">
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number" class="form-control" v-model="form.quantity" step="1" min="1" max="100" required readonly>
              </div>

              <div class="form-group">
                <label for="contact">Phone or Email</label>
                <input id="contact" type="text" class="form-control" v-model="form.contact">
              </div>

              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input
                      id="self_chosen"
                      v-model="form.self_chosen"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                  >
                  <label
                      class="custom-control-label"
                      for="self_chosen"
                  >Self-Chosen Brand</label>
                </div>
              </div>

              <div class="form-group">
                <label for="portal">Payment Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="notes">Internal Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Gift Card Details</p>

            <template v-if="form.data">

              <template v-if="form.data.cardNumber">
                <p class="h4 text-uppercase">Claim Code <span class="text-success mr-1">{{ form.data.cardNumber }}</span>
                <button type="button" class="btn btn-secondary btn-sm d-print-none" @click="copyToClipboard(form.data.cardNumber)"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </p>
              </template>

              <template v-if="form.data.pinCode">
                <p class="text-uppercase">Pin Code {{ form.data.pinCode }}</p>
              </template>

              <template v-if="form.data.product">
                <p class="m-0">Product {{ form.data.product.productName }}</p>
                <p class="m-0">Country {{ form.data.product.countryCode }}</p>
                <p class="m-0">Amount ${{ form.data.product.totalPrice }}</p>
              </template>

            </template>

          </div><!--Body-->
        </div><!--Card-->


        <div class="card d-print-none">
          <div class="card-body">

            <p class="card-title">Send a Message</p>

            <template v-if="!form.contact">
              <div class="alert alert-warning">
                Add a phone or email to send a message
              </div>
            </template>

            <template v-if="form.contact">

            <form @submit.prevent="postMessage()">

            <div class="form-group">
              <label for="message">Message</label>
              <textarea id="message" class="form-control" v-model="messageForm.message"></textarea>
              <small class="form-text text-muted">Optional</small>
            </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

            </form>

            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="messages && messages.length > 0">

          <p class="card-title mt-3 mb-0">{{ messages.length }} Messages</p>
          
          <template v-for="message in messages">
            <p class="m-0 text-muted small">To: {{ message.to }}</p>
            <template v-if="message.subject">
              <p class="m-0 text-muted small">Subject: {{ message.subject }}</p>
            </template>
            <p class="outbound m-0" v-html="message.content"></p>
            <p class="text-muted small m-0">{{ message.scheduled_at | dateTimezone }}</p>
            <span class="badge text-uppercase m-0" :class="setMessageStatus(message)">{{ message.last_status }}</span>
          </template>
            
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">How to Redeem</p>

            <template v-if="currentCard">

            <template v-if="currentCard.key">
              <img :src="$root.assets_url+'images/gift_cards/cards/'+currentCard.key+'.png'" class="img-fluid mb-3" width="100" />
            </template>

            <template v-if="brand.redeemInstruction">
              <p class="m-0">REDEEM INSTRUCTIONS</p>
              <p class="m-0">{{ brand.redeemInstruction.verbose }}</p>
            </template>

            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->

  </div>
</template>
<script>
export default {

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      brand: {},
      portals: [],
      total: 0,
      gift_cards: [],
      messageForm: {
        message: null
      },
      messages: []
    }
  },

  computed: {

    currentPortal() {
      if(!this.form.portal) {
        return {};
      }

      return _.find(this.portals, {uuid: this.form.portal});
    },

    currentCard() {
      if(!this.form.brand) {
        return;
      }

      var card = this.gift_cards.find(card => card.name === this.form.brand);

      if(!card) {
        return;
      }

      return card;
    }

},

  created() {
    this.resetForm();
    this.getRecord();
    this.getMessages();
  },

  methods: {

    resetForm() {
      this.form = {
        amount: null,
        contact: null,
        client: null,
        token: null,
        status: null,
        notes: null,
        portal: null,
        self_chosen: false,
      }
    },


    getPortals() {
      this.$http.get('/portals?limit=150&fields=name,uuid,link,domain')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getRecord() {

      this.processing = true;

      this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              this.form = response.data;

              this.processing = false;

              this.getPortals();

              this.getGiftCards();

              this.getBrand();

            }
          })
    },

    getBrand() {
      if(!this.form.data || !this.form.data.product || !this.form.data.product.productId) {
        return;
      }

      this.$http.get(this.$route.meta.base_url+'/brands?brand='+this.form.data.product.productId)
          .then(response => {
            if(response && response.data) {
              this.brand = response.data;
            }
          })
    },


    postForm() {

      this.processing = true;

      this.$http.post(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' saved', type: 'success'});
              }

            }
          })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' restored', type: 'success'});
                this.form.deleted_at = null;
                this.form.updated_at = new Date().toISOString();
              }

            }
          })
    },


    trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' trashed', type: 'success'});
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    setPortalLink() {
      if(!this.currentPortal || !this.currentPortal.uuid) {
        return;
      }

      if(!this.form.url || this.form.url == '' || this.form.url == 'null') {
        return;
      }

      return 'https://'+this.currentPortal.link+'.'+this.currentPortal.domain+'/g/'+this.form.url;
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },

    setStatusClass(status) {
      if(!status) {
        return;
      }

      status = status.toLowerCase();

      if(status == 'successful') {
        return 'badge-primary';
      }

      if(status == 'failed') {
        return 'badge-danger';
      }

      if(status == 'pending') {
        return 'badge-warning';
      }

      if(status == 'activated') {
        return 'badge-success';
      }

      return 'badge-secondary';
    },

    getGiftCards () {
      this.$axios.get(this.$root.assets_url+'js/gift_cards.json')
      .then(response => {
          if(response && response.data) {
              this.gift_cards = response.data;
          }
      })
    },

    resetMessageForm() {
      this.messageForm = {
        message: null
      }
    },

    getMessages() {
      this.messages = [];
      this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id+'/messages')
      .then(response => {
        if(response && response.data) {
          this.messages = response.data;
        }
      })
    },

    postMessage() {
      this.processing = true;

      this.$http.post(this.$route.meta.base_url+'/'+this.$route.params.id+'/messages', {
        message: this.messageForm.message || '',
        contact: this.form.contact,
        client: this.form.client,
        portal_link: this.setPortalLink()
      })
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.resetMessageForm();

          this.getMessages();
        }
      })
    },

    setMessageStatus(message)
    {
      if(!message.last_status) {
        return;
      }

      if(message.last_status == 'sent') {
        return 'bg-primary';
      }

      if(message.last_status == 'failed') {
        return 'bg-danger';
      }

      if(message.last_status == 'delivered') {
        return 'bg-success';
      }

      return 'bg-secondary';
    }

  }
}
</script>