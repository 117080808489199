<template>
    <div>
  
      <page-tabs page="settings" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Create {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">

                <div class="form-group">
                    <label for="name">Name</label>
                    <input id="name" type="text" v-model="form.name" class="form-control" :disabled="processing" required>
                </div>


                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Search Agencies</p>

              <form @submit.prevent="searchRecords()">
              
                <div class="form-group">
                    <label for="search">Search</label>
                    <input id="search" type="text" v-model="queries.search" class="form-control" :disabled="processing">
                </div>

                <button type="submit" class="btn btn-primary" :disabled="processing">
                  Search
                </button>

              </form>
            </div>
          </div>


        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">

                <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} Agencies
                </p>
  
                <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No agencies found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
          <div class="row mt-3">
            <div class="col-12 text-center">
  
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
  
            </div><!--Col-->
          </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  export default {
    
    data () {
      return {
        processing: false,
        downloading: false,
        total: 0,
        queries: {},
        downloads: [],
        records: [],
        fields: [],
        form: {},
        options: {
          portals: []
        },
        columns: [
        { key: 'name', label: 'Name'},
        { key: 'reference', label: 'Reference'},
        { key: 'status', label: 'Status'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
        ],
      }
    },

    mounted() {
      this.refreshRecords();
    },
  
    created() {
      this.setFields();
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortals();
    },
  
    methods: {

      setFields () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/app_fields.json')
          .then(response => {
              if(response && response.data) {
                  this.fields = response.data[this.$route.meta.base_url];
              }
          })
        },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          name: '',
          redirect_url: '',
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
  
      setTitle() {
        document.title = this.total + ' ' + 'Agencies | '+this.$root.title;
      },
  
      postForm() {
        this.processing = true;

        if(!this.form.name) {
            this.form.name = this.form.redirect_url;
        }

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.status) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;

              if(!this.records) {
                this.records = [];
              }

              this.setTitle();

              this.records.unshift(response.data);
              this.$eventBus.$emit('alerts', {'message': this.$route.meta.title+' added', 'type': 'success'});
            }
  
          }
        })
      },
  
      setPortalName(record) {
  
        if(!record.portal) {
          return;
        }
  
        var portal = _.find(this.options['portals'], {'uuid': record.portal});
  
        if(!portal || !portal.name) {
          return;
        }
  
        return portal.name;
      },
  
      getPortals () {
        this.$http.get('/portals?limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data) {
                this.options['portals'] = response.data;

                if(!this.form.portal && this.options['portals'] && this.options['portals'].length) {
                  this.form.portal = this.options['portals'][0].uuid;
                }
              }
            })
      },

        
    },
  }
  </script>