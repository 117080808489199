<template>
  <div>

    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="max_payments">Max # of Payments</label>
                <input
                  id="max_payments"
                  v-model="form.max_payments"
                  type="number"
                  class="form-control"
                  required
                >
              </div>
       
              <div class="form-group">
                <label for="max_duration">Max Duration</label>
                <input
                  id="max_duration"
                  v-model="form.max_duration"
                  type="number"
                  class="form-control"
                  required
                >
                <small class="text-muted">In Days</small>
              </div>

              <div class="form-group">
                <label for="fee">Pay Plan Fee</label>
                <input
                  id="fee"
                  v-model="form.fee"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="fee_calculation">Fee Calculation</label>
                <input
                  id="fee_calculation"
                  v-model="form.fee_calculation"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="transaction_fee">Transaction Fee</label>
                <input
                  id="transaction_fee"
                  v-model="form.transaction_fee"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="transaction_fee_calculation">Transaction Fee Calculation</label>
                <input
                  id="transaction_fee_calculation"
                  v-model="form.transaction_fee_calculation"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="autopay"
                  v-model="form.autopay"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="autopay"
                > Require AutoPay</label>
              </div>

              <div class="form-group">
                <label for="terms">Terms</label>
                <textarea id="terms" class="form-control" v-model="form.terms" rows="3"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-6 col-sm-12">

        <div class="card">
          <div class="card-body">

            <template v-if="client_plans && client_plans.length > 0">
              <h5>Client Plans</h5>
              <ul>
                <li v-for="client_plan in client_plans">
                  <template v-if="client_plan.amount">
                    <strong>Amount:</strong> {{ client_plan.amount }}<br>
                  </template>
                  <template v-if="client_plan.autopay">
                    <strong>AutoPay:</strong> {{ client_plan.autopay }}<br>
                  </template>
                  <template v-if="client_plan.created_at">
                    <strong>Created:</strong> {{ client_plan.created_at | datetime }}<br>
                  </template>
                </li>
              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      portals: [],
      locations: [],
      client_plans: [],
      total: 0
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetForm() {
      this.form = {
        name: null,
        max_payments: 12,
        max_duration: 365,
        fee: 1,
        fee_calculation: 'one-time',
        transaction_fee: 0,
        transaction_fee_calculation: 'one-time',
        autopay: true,
        terms: null
      }
    },

    getLocations () {
        this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
      this.$http.get('/portals?limit=150&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    },


    getClientPlans() {

      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/client_plans?plan='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.client_plans = response.data;
        }
      })
    },

    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                if(!response.data.fields) {
                  this.form.fields = {};
                }

                this.getClientPlans();

                this.processing = false;
            }
        })
    },

    postForm() {

      this.processing = true;

      this.$http.post(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                this.form.deleted_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  }

  }
}
</script>