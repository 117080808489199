<template>
  <div>

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-end">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control" v-model="form.status" :disabled="processing" required>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'status_'+status_index" :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <fieldset>
                  <legend>Terminal Type</legend>
                  <label
                      class="mr-2 text-capitalize"
                      for="mobile"
                  >
                    <input
                        id="mobile"
                        v-model="form.type"
                        type="radio"
                        name="type"
                        value="mobile"
                    > Mobile Reader
                  </label>
                  <label
                      class="mr-2 text-capitalize"
                      for="dedicated"
                  >
                    <input
                        id="dedicated"
                        v-model="form.type"
                        type="radio"
                        name="type"
                        value="dedicated"
                    > Dedicated Reader
                  </label>
                </fieldset>
              </div>

              <div class="form-group">
                <label for="portal">Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :key="portal.uuid"
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <a
                        :href="'/locations/'+form.location"
                        target="_blank"
                        data-tooltip="Edit Location">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                    </a>
                  </template>

                </label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Location
                  </option>
                  <template v-for="location in locations">
                    <option
                        :key="location.uuid"
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-6">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Transactions</p>

            <div class="alert alert-warning">No transactions found.</div>

          </div>
        </div>

      </div><!--Col-->

      <div class="col-md-3">
        <template v-if="location && location.lat && location.lng">
          <location-maps :record="location" />
        </template>

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">Hardware Guide</p>

            <template v-if="form.type">
              <img :src="'https://ecdassets.s3.amazonaws.com/images/payment-readers/'+form.type+'-terminal.png'" class="img-fluid" :alt="form.type + ' Card Terminal'" width="50%" />
            </template>

          </div>
        </div>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      form: {},
      location: {},
      portals: [],
      locations: [],
      statuses: ['pending', 'active']
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getPortals();
    this.getLocations();
  },

  methods: {


    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;

                if(this.form.location && this.form.location != this.location.uuid) {
                  this.location = {};
                  this.getLocation();
                }
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        type: null,
        status: null,
        portal: null,
        location: null
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;
                this.getLocation();
              }

              this.processing = false;
            }
          })
    },

    getLocations() {
      this.$http.get('/locations?limit=1000&fields=name,uuid&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.locations = response.data;
            }
          })
    },

    getLocation() {
      if(!this.form.location) {
        return;
      }

      this.$http.get('/locations/'+this.form.location)
          .then(response => {
            if(response && response.data) {
              this.location = response.data;
            }
          })
    },

    getPortals() {
      this.$http.get('/portals?limit=1000&fields=name,uuid,link,domain&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                this.form.deleted_at = null;
              }

            }
          })
    },


    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

  },
}
</script>