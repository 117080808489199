<template>
  <div>
    <div class="row my-5 text-center">
      <div class="col-12">
        <a href="/">
          <img
            src="/images/logo.webp"
            width="120"
            alt="GovPayPlan logo"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            theme: ''
        }
    },
 

    methods: {

    }
}
</script>
