<template>
  <div>

    <page-tabs page="settings" />
    
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">Portal Name *</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>


                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Create
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{  $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row mt-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      validating: false,
      total: 0,
      queries: {
        limit: 10,
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {},
      columns: [
      { key: 'name', label: 'Name'},
      { key: 'link', label: 'Subdomain'},
      { key: 'domain', label: 'Domain'},
      { label: 'Link'},
      { key: 'reference', label: 'Reference'},
      { key: 'default', label: 'Default'},
      { key: 'redirect', label: 'Redirect'},
      { key: 'creator', label: 'Creator'},
      { key: 'created_at', label: 'Created'},
      { key: 'updated_at', label: 'Updated'}
      ]
    }
  },

  mounted() {
    this.refreshRecords();
  },

  created() {
    this.resetForm();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
  },

  methods: {

    resetForm() {
      this.form = {
        link: null,
        name: null,
        domain: 'govpayments.io',
        settings: {
          theme: 'auto'
        }
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
            
            if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                    self.records.push(data);
                });
            }
            
            this.queries.skip = this.records.length;

            this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's';
    },

    postForm() {      
      this.processing = true;
      
      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          sessionStorage.clear();

          if(response.status === 201) {
            this.resetForm();
            this.total++;
            this.records.unshift(response.data);
            this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
          }

        }
      })
    },
   

  },
}
</script>