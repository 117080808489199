<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

    <div class="card mb-3">
      <div class="card-body">
        <p class="card-title">
          Create {{ $route.meta.title }}
        </p>
        <form
          @submit.prevent="postForm()"
        >
          <div class="form-group">
            <label for="user_name">Name</label>
            <input
              id="user_name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="user_phone">Phone</label>
            <input
              id="user_phone"
              v-model="form.phone"
              type="tel"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="user_email">Email</label>
            <input
              id="user_email"
              v-model="form.email"
              type="email"
              class="form-control"
              required
            >
          </div>


          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Create
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Search {{ $route.meta.title }}s</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <button type="button" class="btn btn-outline-secondary btn-sm" @click="resetQueries()"><i class="fa fa-eraser"></i> Reset</button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_email">Email</label>
                <input id="search_email" type="email" class="form-control" v-model="queries.email">
              </div>

              <div class="form-group">
                <label for="search_phone">Phone</label>
                <input id="search_phone" type="tel" class="form-control" v-model="queries.phone">
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && (!records || !records.length)">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-outline-primary d-print-none mt-3"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

    data () {
        return {
            processing: false,
            downloading: false,
            records: [],
            downloads: [],
            queries: {},
            current_user: {},
            total: 0,
            columns: [
              {label: 'name', key: 'name'},
              {label: 'phone', key: 'phone'},
              {label: 'email', key: 'email'},
              {label: 'last active', key: 'last_active'},
              {label: 'created', key: 'created_at'}
            ],
            form: {}
        }
    },

    mounted() {
      this.refreshRecords();
    },

    created () {
      this.resetQueries();
      this.resetForm();
      this.getRecords();
    },

    methods: {


      resetQueries() {
        this.queries = {
          search: null,
          email: null,
          phone: null,
          sort: 'created_at',
          sortDirection: 'desc',
          limit: 20,
          skip: 0
        }
      },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                }
            })
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        resetForm() {
          this.form = {
            name: null,
            email: null,
            phone: null
          }
        },

        postForm () {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if (response && response.data) {

              if(response.status === 201) {
                this.records.unshift(response.data);
                this.total++;
                this.resetForm();
              }

              this.processing = false
            }
          })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's';
        },

        updateSortDirection() {

            if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
            } else {
            this.queries.sortDirection = 'asc';
            }

            this.resetRecords();

            this.getRecords();
        },

        sortColumn(column) {

            this.queries.sort = column.key;

            this.updateSortDirection();
        },


        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
        },


    }
}
</script>   