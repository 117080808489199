<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    placeholder="Cashier 1"
                    required
                >
              </div>

                <div class="form-group">
                  <fieldset>
                    <legend>Terminal Type</legend>
                    <label
                        class="mr-2 text-capitalize"
                        for="mobile"
                    >
                      <input
                          id="mobile"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          value="mobile"
                      > Mobile Reader
                    </label>
                    <label
                        class="mr-2 text-capitalize"
                        for="dedicated"
                    >
                      <input
                          id="dedicated"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          value="dedicated"
                      > Dedicated Reader
                    </label>
                  </fieldset>
                </div>

              <template v-if="form.type">
                <img :src="'https://ecdassets.s3.amazonaws.com/images/payment-readers/'+form.type+'-terminal.png'" class="img-fluid" :alt="form.type + ' Card Terminal'" width="50%" />
              </template>

              <div class="form-group">
                <label for="portal">Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <a
                        :href="'/locations/'+form.location"
                        target="_blank"
                        data-tooltip="Edit Location">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                    </a>
                  </template>

                </label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Location
                  </option>
                  <template v-for="location in locations">
                    <option
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Create
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      records: [],
      form: {},
      queries: {},
      total: 0,
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'type', label: 'Type'},
        { key: 'status', label: 'Status'},
        { key: 'portal', label: 'Portal'},
        { key: 'location', label: 'Location'},
        { key: 'created_by', label: 'Created'},
        { key: 'updated_by', label: 'Updated'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      portals: [],
      locations: []
    }
  },

  mounted() {
    this.refreshRecords();
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getLocations();
    this.getPortals();
  },

  methods: {

    resetQueries() {
      this.queries = {
        limit: 10,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        name: null,
        type: 'mobile',
        location: null,
        portal: null,
        status: 'pending'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },


    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.records.unshift(response.data);
              }

            }
          })
    },

    setStatusClass(record) {

      if(record.status == 'pending') {
        return 'badge-danger';
      }

      return 'badge-success';
    },

    getLocations() {
      this.$http.get('/locations?limit=1000&fields=name,uuid&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.locations = response.data;

              if(this.locations[0]) {
                this.form.location = this.locations[0]['uuid'];
              }
            }
          })
    },

    getPortals() {
      this.$http.get('/portals?limit=1000&fields=name,uuid,link,domain&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;

              if(this.portals[0]) {
                this.form.portal = this.portals[0]['uuid'];
              }
            }
          })
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

  }
}
</script>