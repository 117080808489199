<template>
  <div>    
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create {{ $route.meta.title }}
            </p>

            <form
              @submit.prevent="postForm"
            >
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
                <p class="form-text text-muted">You can change the name later.</p>
              </div>

              <div class="form-group">
                <label for="portal">Payment Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option :value="portal.uuid">{{ portal.name || 'Portal' }}</option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Create
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search by name..."
                >
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
export default {
  
  data () {
    return {
      records: [],
      processing: false,
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'amount', label: 'Amount'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      portals: []
    }
  },

  mounted() {
    this.refreshRecords();
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getPortals();
  },

  methods: {

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        name: null,
        amount: 0,
        title: {
          'en': ''
        },
        description: {
          'en': ''
        }
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        search: null,
        archived: false,
        trashed: false
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's';
    },

    postForm() {
        this.processing = true;

        this.form.title['en'] = this.form.name;
        this.form.description['en'] = this.form.name;

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

                if(response.status === 201) {
                  this.resetForm();
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' added', type: 'success'});
                  this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: response.data.uuid}});
                }

                this.processing = false;

            }
        });
    },

    getPortals() {
      this.$http.get('/portals?limit=100&fields=name,uuid&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;

              if(this.portals[0] && this.portals[0].uuid) {
                this.form.portal = this.portals[0].uuid;
              }
            }
          })
    },


  }
}
</script>
