<template>
        <div class="table-responsive">
              <table class="table table-striped table-hover">

    <thead>
      <tr>
        <th><i class="fa fa-edit"></i> Edit</th>
        <template v-for="column in fields">
          <th>
            <template v-if="column.key">
              <span
                class="text-capitalize link-hover"
                @click="sortColumn(column)"
              >{{ column.label }}</span>
            </template>
  
            <template v-if="!column.key">
              <span class="text-capitalize">{{ column.label }}</span>
            </template>
  
            <template v-if="column.key == queries.sort">
              <button
                type="button"
                class="btn btn-outline-secondary btn-sm ml-1"
                :disabled="processing"
                @click="updateSortDirection()"
              >
                <template v-if="queries.sortDirection == 'asc'">
                  <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                </template>
  
                <template v-if="queries.sortDirection == 'desc'">
                  <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                </template>
              </button>
            </template>
          </th>
        </template>
      </tr>
    </thead>

    <tbody>
        <template v-if="records && records.length > 0">
        <template v-for="record in records">
            <tr>
                <td><a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a></td>
                <template v-for="column in fields">
                <td>

                  <template v-if="column.key == 'status' && record[column.key]">

                    <span :class="'text-uppercase badge ' + setStatusClass(record[column.key])">
                      {{ record[column.key] }}
                    </span>

                  </template>

                  <template v-else-if="column.key == 'default'">

                    <template v-if="record[column.key]">
                      <i class="fa fa-check text-success"></i> Default
                    </template>

                    <template v-if="!record[column.key]">
                      <i class="fa fa-times text-danger"></i> Not default
                    </template>

                  </template>

                  <template v-else-if="column.key == 'location'"> 
                    <template v-if="record[column.key]">
                      {{ setLocation(record[column.key]) }}
                    </template>

                    <template v-if="!record[column.key]">
                      <i class="fa fa-times text-danger"></i> Not set
                    </template>
                  </template>

                  <template v-else-if="column.key == 'portal'"> 
                    <template v-if="record[column.key]"> 
                      {{ setPortal(record[column.key]) }}
                    </template>

                    <template v-if="!record[column.key]">
                      <i class="fa fa-times text-danger"></i> Not set
                    </template>
                  </template>

                  <template v-else-if="column.key">
                  {{ record[column.key] }}
                  </template>

                  <template v-else="!column.key">
                  {{ column.label }}
                  </template>

                </td>
                </template>
            </tr>
        </template>
        </template>
    </tbody>

    </table>

    </div><!--Responsive-->

  </template>
  <script>
  export default {
  
  props: ['fields', 'queries', 'base_url', 'records'],
  
  data() {
      return {
        locations: [],
        portals: []
      }
  },
  
  created() {
    this.getLocations();
    this.getPortals();
  },
  
  methods: {
  
      updateSortDirection() {
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.$eventBus.$emit('refreshRecords', this.base_url);
      },
  
      sortColumn(column) {
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
  
      },

      setStatusClass(status) {
      if(!status || status == '' || status == null) {
        return;
      }

      if(typeof status === 'number') {
        status = status.toString();
      }

      status = status.toLowerCase();

      let statuses = {
        'successful': 'bg-success',
        'activated': 'bg-success',
        'failed': 'bg-danger',
        'pending': 'bg-warning',
        'default': 'bg-primary',
        'outstanding': 'bg-danger',
        'overdue': 'bg-danger',
        'paid': 'bg-success',
        'unpaid': 'bg-danger',
        'voided': 'bg-danger',
        'void': 'bg-danger',
      }

      return statuses[status] || 'bg-primary';
    },

    getLocations() {

      var locations = sessionStorage.getItem('locations');

      if(locations) {
        this.locations = JSON.parse(locations);
        return;
      }

      this.$http.get('/locations?limit=1000&sort=default&sortDirection=asc&fields=uuid,name')
      .then(response => {
        if(response && response.data) {
          this.locations = response.data;

          sessionStorage.setItem('locations', JSON.stringify(this.locations));
        }
      })
    },

    getPortals() {

      var portals = sessionStorage.getItem('portals');

      if(portals) {
        this.portals = JSON.parse(portals);
        return;
      }

      this.$http.get('/portals?limit=1000&sort=default&sortDirection=asc&fields=uuid,name')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;

          sessionStorage.setItem('portals', JSON.stringify(this.portals));
        }
      })
    },

    setLocation(location) {
      if(!location) {
        return;
      }

      if(!this.locations || !this.locations.length) {
        return;
      }

      var record = this.locations.find(l => l.uuid == location);

      if(!record || !record.name) {
        return;
      }

      return record.name;
    },

    setPortal(portal) {
      if(!portal) {
        return;
      }

      if(!this.portals || !this.portals.length) {
        return;
      }

      var record = this.portals.find(p => p.uuid == portal);
      
      if(!record || !record.name) {
        return;
      }

      return record.name;
    },
  
  }
  
  }
  </script>